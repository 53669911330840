import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  getLocalTimeZone,
  now,
  parseAbsolute,
  toZoned,
} from '@internationalized/date'

const MILLIS_PER_SECOND = 1000;
const SECONDS_PER_DAY = 86400;

type InternationalizedDate = CalendarDate | CalendarDateTime | ZonedDateTime

export function dateToZonedDateTime(date: Date): ZonedDateTime {
  return dateStringToZonedDateTime(date.toISOString())
}

export function internationalizedDateToDate(internationalizedDate: InternationalizedDate): Date {
  if (internationalizedDate instanceof CalendarDate || internationalizedDate instanceof CalendarDateTime) {
    return internationalizedDate.toDate(getLocalTimeZone())
  }

  return internationalizedDate.toDate()
}


const RR_DATE_REGEX =
  /(?<yearMonthDayHoursMinutesSeconds>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(?<msSeparator>:)(?<miliseconds>\d{0,3})(?<utcOffsetSymbol>[-+]{1})(?<utcOffsetHours>\d{2})(?<utcOffsetMinutes>\d{2})/

export function redrockDateStringToZonedDateTime(
  redrockDateString: string,
): ZonedDateTime {
  return dateStringToZonedDateTime(
    redrockDateString.replace(
      RR_DATE_REGEX,
      '$<yearMonthDayHoursMinutesSeconds>.$<miliseconds>$<utcOffsetSymbol>$<utcOffsetHours>:$<utcOffsetMinutes>',
    ),
  )
}

const INTERNATIONALIZED_DATE_REGEX =
  /(?<yearMonthDayHoursMinutesSeconds>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(?<msSeparator>\.)(?<miliseconds>\d{0,3})(?<utcOffsetSymbol>[-+]{1})(?<utcOffsetHours>\d{2})(?<utcOffsetSeparator>:)(?<utcOffsetMinutes>\d{2})(?<timeZoneIdentifier>\[.*\])?/

export function internationalizedDateToRedRockDateString(
  internationalizedDate: InternationalizedDate,
): string {
  return toStringWithMs(
    ensuredZonedDateTime(internationalizedDate),
  ).replace(
    INTERNATIONALIZED_DATE_REGEX,
    `$<yearMonthDayHoursMinutesSeconds>:$<miliseconds>$<utcOffsetSymbol>$<utcOffsetHours>$<utcOffsetMinutes>`,
  )
}

export function toStartOfDay(internationalizedDate: InternationalizedDate): InternationalizedDate {
  if (internationalizedDate instanceof CalendarDate) {
    return internationalizedDate
  }

  return internationalizedDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
}

export function toEndOfDay(internationalizedDate: InternationalizedDate): InternationalizedDate {
  if (internationalizedDate instanceof CalendarDate) {
    return internationalizedDate
  }

  return internationalizedDate.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  })
}

export function localNow(): ZonedDateTime {
  return now(getLocalTimeZone())
}

// HELPER FNS

function ensuredZonedDateTime(internationalizedDate: InternationalizedDate) {
  return internationalizedDate instanceof ZonedDateTime ? internationalizedDate : toZoned(internationalizedDate, getLocalTimeZone())
}

// zoned date time toString() method does not include milliseconds if they are 0
function toStringWithMs(zonedDateTime: ZonedDateTime) {
  if (zonedDateTime.millisecond === 0) {
    const zdtWithSevenMs = zonedDateTime.set({ millisecond: 7 })
    return zdtWithSevenMs.toString().replace('007', '000')
  } else {
    return zonedDateTime.toString()
  }
}

function dateStringToZonedDateTime(dateString: string): ZonedDateTime {
  return parseAbsolute(dateString, getLocalTimeZone())
}

export function getDayDiffForRedrockDateString (redrockDateString: string) {
  const dateToCompare = redrockDateStringToZonedDateTime(redrockDateString).toDate()
  dateToCompare.setHours(0,0,0,0);

  const today = new Date();
  today.setHours(0,0,0,0);

  const diffInSeconds = ((today.getTime() - dateToCompare.getTime()) / MILLIS_PER_SECOND)

  return Math.floor(diffInSeconds / SECONDS_PER_DAY);
}
